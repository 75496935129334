import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CompetitionBlock from "../components/MainStats/CompetitionBlock"
import LastUpdated from "../components/LastUpdated/LastUpdated"
import CopyBlock from "../components/Copy/CopyBlock"
import InfoBar from '../components/Utilities/InfoBar'
import SelectMenuNav from "../components/Utilities/SelectMenuNav"


class IntAllTimePage extends React.Component {

  render() {

    const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    let [{
      freeKicks: mIntFreeKicks,
      goals: mIntGoals,
      assists: mIntAssists,
      hatTricks: mIntHatTricks,
      goalsPerGame: mIntGoalsPerGame,
      minsPerGoal: mIntMinsPerGoal,
      minsPerGoalContribution: mIntMinsPerGoalCont,
      pens: mIntPens,
      apps: mIntApps,
    }] = mdata.filter(d => d.node.competition === "All Time Internationals").map(d => d.node)

    let [{
      freeKicks: rIntFreeKicks,
      goals: rIntGoals,
      assists: rIntAssists,
      hatTricks: rIntHatTricks,
      goalsPerGame: rIntGoalsPerGame,
      minsPerGoal: rIntMinsPerGoal,
      minsPerGoalContribution: rIntMinsPerGoalCont,
      pens: rIntPens,
      apps: rIntApps,
    }] = rdata.filter(d => d.node.competition === "All Time Internationals").map(d => d.node)

    let [{
      goals: mCIntGoals,
      assists: mCIntAssists,
      goalsPerGame: mCIntGoalsPerGame,
      minsPerGoal: mCIntMinsPerGoal,
      minsPerGoalContribution: mCIntMinsPerGoalCont,
      pens: mCIntPens,
      apps: mCIntApps,
    }] = mdata.filter(d => d.node.competition === "Competitive Internationals").map(d => d.node)

    let [{
      goals: rCIntGoals,
      assists: rCIntAssists,
      goalsPerGame: rCIntGoalsPerGame,
      minsPerGoal: rCIntMinsPerGoal,
      minsPerGoalContribution: rCIntMinsPerGoalCont,
      pens: rCIntPens,
      apps: rCIntApps,
    }] = rdata.filter(d => d.node.competition === "Competitive Internationals").map(d => d.node)

    let [{
      goals: rEurosGoals,
      apps: rEurosApps,
    }] = rdata.filter(d => d.node.competition === "Copa America / Euros").map(d => d.node)

    let [{
      goals: rEurosQGoals,
      apps: rEurosQApps,
    }] = rdata.filter(d => d.node.competition === "Euros Qualifier").map(d => d.node)

    let [{
      goals: rWCQGoals,
      apps: rWCQApps,
    }] = rdata.filter(d => d.node.competition === "World Cup Qualifiers").map(d => d.node)

    return (
      <Layout>
        <SEO
          title={"International Goals and Stats - Messi vs Ronaldo All Time International Stats"}
          description={`Compare Messi vs Ronaldo all time international stats, from goals, assists and penalties to free kicks, hat-tricks and scoring ratios.`}
          canonicalPath={`/international-stats/`}
        />

        <h1 className={`sr-only`}><span>Messi vs Ronaldo All Time</span> International Stats</h1>

        <SelectMenuNav
          current=''
          parentPath="international-stats"
        />

        <CompetitionBlock competition="All Time Internationals" type="int" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="Competitive Internationals" type="int" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="International Friendlies" type="int" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="Copa America / Euros" type="int" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="World Cup" type="int" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="All Major Tournaments" type="int" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="All Major Tournament Knockouts" type="int" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="World Cup Qualifiers" type="int" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="Euros Qualifier" compTitle="European Championship Qualifiers" type="int" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="Confederations Cup" type="int" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="UEFA Nations League" type="int" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="Finalissima" type="int" mdata={mdata} rdata={rdata} />

        <CopyBlock classes="mt-16">
          <h2>Messi and Ronaldo International Stats</h2>

          <InfoBar />

          <p>Lionel Messi and Cristiano Ronaldo have both had impressive international careers for Argentina and Portugal respectively.</p>

          <p>Messi is Argentina's all time top goalscorer with <strong className="text-messi">{mIntGoals} goals in {mIntApps} caps, scoring {Number(mIntGoalsPerGame).toFixed(2)} goals per game</strong>. He's also Argentina's all time top provider with <strong className="text-messi">{mIntAssists} assists</strong>.</p>

          <p>Likewise, Ronaldo is Portugal's all time leading goalscorer with an incredible <strong className="text-ronaldo">{rIntGoals} goals in {rIntApps} caps, scoring an average of {Number(rIntGoalsPerGame).toFixed(2)} goals per game</strong>.</p>

          <p>If we look at minutes played, Messi scores <strong className="text-messi">a goal every {Number(mIntMinsPerGoal).toFixed(1)} minutes</strong>, whilst Ronaldo scores <strong className="text-ronaldo">a goal every {Number(rIntMinsPerGoal).toFixed(1)} minutes</strong>.</p>
         
          <p><strong className="text-highlight">Factoring in assists</strong>, Messi directly contributes to a goal <strong className="text-messi">every {Number(mIntMinsPerGoalCont).toFixed(1)} minutes</strong> for Argentina, with Ronaldo scoring or assisting <strong className="text-ronaldo">every {Number(rIntMinsPerGoalCont).toFixed(1)} minutes</strong> for Portugal.</p>
            
          <p>Clearly, both players have contributed to their national teams significantly and prolifically, and have done so for over 15 years.</p>

          <h3>International Trophies</h3>

          <p>Cristiano Ronaldo has reached two major international finals with Portugal, both at the European Championships. His first, way back in <strong className="text-highlight">2004</strong>, saw his Portugal side stunned on home soil against pre-tournament outsiders Greece, who ran out 1-0 winners.</p>

          <p>His second major final took place in Paris 12 years later, where this time the favoured team on home soil was the opposition: France. Unfortunately for Ronaldo, he suffered an injury after just eight minutes and eventually had to be substituted in the 25th minute. More fortunately though, after a tense goalless ninety minutes, Portugal's Eder struck the winning goal in extra-time from 25 yards. Portugal and Cristiano Ronaldo were <strong className="text-highlight">European champions</strong> for the first time.</p>

          <p>In 2019, Portugal also won the very first edition of the <strong className="text-highlight">UEFA Nations League</strong>, a brand new competition brought in to replace stale international friendlies. Ronaldo played no part in the four group stage matches, but when Portugal qualified for the semi-final stage, he came back with a bang, scoring a hat-trick to beat Switzerland 3-1 and put Portugal in the final. There they faced the Netherlands, with Gonçalo Guedes scoring the game's only goal.</p>
          
          <p>Not a Euros or a World Cup, but a 2nd international trophy for Ronaldo in 4 years nonetheless.</p>

          <h4>Ronaldo's International Finals</h4>
          <ul>
            <li><span role="img" aria-label="red cross">❌</span> Euro 2004 <small>- 0-1 vs Greece</small></li>
            <li><span role="img" aria-label="trophy">🏆</span> Euro 2016 <small>- 1-0 (AET) vs France</small></li>
            <li><span role="img" aria-label="trophy">🏆</span> UEFA Nations League 2019 <small>- 1-0 vs Netherlands</small></li>
          </ul>

          <h4>Messi's International Finals</h4>
          <ul>
            <li><span role="img" aria-label="trophy">🏆</span> U20 World Cup <small>- 2-1 vs Nigeria</small></li>
            <li><span role="img" aria-label="red cross">❌</span> Copa America 2007 <small>- 0-3 vs Brazil</small></li>
            <li><span role="img" aria-label="trophy">🏆</span> Beijing Olympics 2008 (U23) <small>- 1-0 vs Nigeria</small></li>
            <li><span role="img" aria-label="red cross">❌</span> World Cup 2014 <small>- 0-1 (AET) vs Germany</small></li>
            <li><span role="img" aria-label="red cross">❌</span> Copa America 2015 <small>- 0-0 vs Chile (1-4 pens)</small></li>
            <li><span role="img" aria-label="red cross">❌</span> Copa America 2016 <small>- 0-0 vs Chile (2-4 pens)</small></li>
            <li><span role="img" aria-label="trophy">🏆</span> Copa America 2021 <small>- 1-0 vs Brazil</small></li>
            <li><span role="img" aria-label="trophy">🏆</span> Finalissima 2022 <small>- 3-0 vs Italy</small></li>
            <li><span role="img" aria-label="trophy">🏆</span> World Cup 2022 <small>- 3-3 vs France (4-2 pens)</small></li>
            <li><span role="img" aria-label="trophy">🏆</span> Copa America 2024 <small>- 1-0 vs Colombia (AET)</small></li>
          </ul>

          <p>Messi's long awaited international triumph with the full senior Argentina team came at the 2021 Copa America, where his Argentina side beat favourites Brazil in the final at the Maracana. This ended Brazil's 46 year long ubeaten run in Copa America matches on home soil, and ended Argentina's 28 year major trophy drought! Messi also picked up the tournament's awards for Best Player and the Golden Boot.</p>

          <p>In 2022, he then won the Finalissima aginst European champions Italy before finally getting his hands on the big one later that year! Messi scored 2 goals in the final as his Argentina side beat France on penalties after a 3-3 draw to lift the World Cup! Messi also picked up the Golden Ball award for the tournament's best player, as well as the Silver Boot for finishing as the 2nd top scorer.</p>
            <p>In 2024, Messi's Argentina became just the second team in men's international football history to win 3 consecutive major tournaments (with a World Cup included - after Spain in 2008, 2010, 2012) when his side defeated Colombia after extra-time to win another Copa America trophy!</p>

          <p>Looking beyond Leo Messi's seven major finals, he also competed in the <strong className="text-highlight">2008 Olympics</strong>, where an U23 Argentina side (plus three over age players) won every game en route to the Gold Medal.</p>

          <p>In addition to this, Messi also won the <strong className="text-highlight">FIFA U20 World Cup</strong> in 2005, scoring a Golden Boot winning 6 goals (including 2 in the final) along the way.</p>

          <h2>International Tournament Goals & Assists</h2>

          <p>Ronaldo made his major tournament debut on home soil at Euro 2004, in which he scored 2 goals and made it all the way to the final before a shock defeat to Greece. Messi debuted at the 2006 World Cup, where he scored 13 minutes into his first appearance; further goals scored at the 2014 and 2018 World Cups granted Messi the unique record of being the only player to score at a World Cup in his teens, twenties and thirties.</p>

          <h4>Messi Major International Tournament Goals & Assists</h4>

          <div className="w-full overflow-x-scroll scrolling-touch text-sm">
            <table>
              <thead>
                <tr>
                  <th>Tournament</th>
                  <th>Goals <small>(Group/KO)</small></th>
                  <th>Assists <small>(Group/KO)</small></th>
                  <th>Apps <small>(Group/KO)</small></th>
                  <th>Out in</th>
                  <th>Awards</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>World Cup 2006</td>
                  <td>1 <small>(1/0)</small></td>
                  <td>1 <small>(1/0)</small></td>
                  <td>3 <small>(2/1)</small></td>
                  <td>Quarters (pens)</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Copa America 2007</td>
                  <td>2 <small>(0/2)</small></td>
                  <td>1 <small>(1/0)</small></td>
                  <td>6 <small>(3/3)</small></td>
                  <td>Final</td>
                  <td>Best Young Player</td>
                </tr>
                <tr>
                  <td>World Cup 2010</td>
                  <td>0 <small>(0/0)</small></td>
                  <td>1 <small>(0/1)</small></td>
                  <td>5 <small>(3/2)</small></td>
                  <td>Quarters</td>
                  <td>1 MOTM</td>
                </tr>
                <tr>
                  <td>Copa America 2011</td>
                  <td>0 <small>(0/0)</small></td>
                  <td>3 <small>(2/1)</small></td>
                  <td>4 <small>(3/1)</small></td>
                  <td>Quarters (pens)</td>
                  <td>2 MOTM</td>
                </tr>
                <tr>
                  <td>World Cup 2014</td>
                  <td>4 <small>(4/0)</small></td>
                  <td>1 <small>(0/1)</small></td>
                  <td>7 <small>(3/4)</small></td>
                  <td>Final (aet)</td>
                  <td>Golden Ball, 4 MOTM</td>
                </tr>
                <tr>
                  <td>Copa America 2015</td>
                  <td>1 <small>(1/0)</small></td>
                  <td>3 <small>(0/3)</small></td>
                  <td>6 <small>(3/3)</small></td>
                  <td>Final (pens)</td>
                  <td>Best Player, 4 MOTM</td>
                </tr>
                <tr>
                  <td>Copa America 2016</td>
                  <td>5 <small>(3/2)</small></td>
                  <td>4 <small>(0/4)</small></td>
                  <td>5 <small>(2/3)</small></td>
                  <td>Final (pens)</td>
                  <td>3 MOTM</td>
                </tr>
                <tr>
                  <td>World Cup 2018</td>
                  <td>1 <small>(1/0)</small></td>
                  <td>2 <small>(0/2)</small></td>
                  <td>4 <small>(3/1)</small></td>
                  <td>Last 16</td>
                  <td>1 MOTM</td>
                </tr>
                <tr>
                  <td>Copa America 2019</td>
                  <td>1 <small>(1/0)</small></td>
                  <td>1 <small>(0/1)</small></td>
                  <td>6 <small>(3/3)</small></td>
                  <td>Semis</td>
                  <td>1 MOTM</td>
                </tr>
                <tr>
                  <td>Copa America 2021</td>
                  <td>4 <small>(3/1)</small></td>
                  <td>5 <small>(2/3)</small></td>
                  <td>7 <small>(4/3)</small></td>
                  <td>Winners</td>
                  <td>Best Player, Golden Boot, 4 MOTM</td>
                </tr>
                <tr>
                  <td>World Cup 2022</td>
                  <td>7 <small>(2/5)</small></td>
                  <td>3 <small>(1/2)</small></td>
                  <td>7 <small>(3/4)</small></td>
                  <td>Winners</td>
                  <td>Golden Ball, Silver Boot, 5 MOTM</td>
                </tr>
                <tr>
                  <td>Copa America 2024</td>
                  <td>1 <small>(0/1)</small></td>
                  <td>1 <small>(1/0)</small></td>
                  <td>5 <small>(2/3)</small></td>
                  <td>Winners</td>
                  <td>1 MOTM</td>
                </tr>
                <tr>
                  <td className="text-highlight font-bold">TOTALS</td>
                  <td className="text-highlight font-bold">27 <small>(16/11)</small></td>
                  <td className="text-highlight font-bold">26 <small>(8/18)</small></td>
                  <td className="text-highlight font-bold">65 <small>(34/31)</small></td>
                  <td className="text-highlight font-bold"></td>
                  <td className="text-highlight font-bold">4 best player, <br />1 Golden Boot, <br />1 Silver Boot, <br />26 MOTM</td>
                </tr>
              </tbody>
            </table>
          </div>

          <p>Interestingly, Messi has scored the majority of his goals in the group stages at international tournaments, but has made considerably more assists in the knockout rounds, with <strong className="text-messi">18 of his 26 assists being provided in the knockout stages</strong>.</p>

          <p>Having played in 12 major international tournaments, Messi actually has the record for the most goals contributions in major tournament history, both overall and in the knockout rounds specifically.</p>

          <p>He also has the record for most goal contributions in the history of the World Cup, with his 13 goals and 8 assists surpassing Pele's 12 goals and 8 assists.</p>

          <h4>Ronaldo Major International Tournament Goals & Assists</h4>

          <div className="w-full overflow-x-scroll scrolling-touch text-sm mb-8">
            <table>
              <thead>
                <tr>
                  <th>Tournament</th>
                  <th>Goals <small>(Group/KO)</small></th>
                  <th>Assists <small>(Group/KO)</small></th>
                  <th>Apps <small>(Group/KO)</small></th>
                  <th>Out in</th>
                  <th>Awards</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Euro 2004</td>
                  <td>2 <small>(1/1)</small></td>
                  <td>2 <small>(1/1)</small></td>
                  <td>6 <small>(3/3)</small></td>
                  <td>Final</td>
                  <td></td>
                </tr>
                <tr>
                  <td>World Cup 2006</td>
                  <td>1 <small>(1/0)</small></td>
                  <td>0 <small>(0/0)</small></td>
                  <td>6 <small>(2/4)</small></td>
                  <td>Semis</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Euro 2008</td>
                  <td>1 <small>(1/0)</small></td>
                  <td>1 <small>(1/0)</small></td>
                  <td>3 <small>(2/1)</small></td>
                  <td>Quarters</td>
                  <td>1 MOTM</td>
                </tr>
                <tr>
                  <td>World Cup 2010</td>
                  <td>1 <small>(1/0)</small></td>
                  <td>1 <small>(1/0)</small></td>
                  <td>4 <small>(3/1)</small></td>
                  <td>Last 16</td>
                  <td>3 MOTM</td>
                </tr>
                <tr>
                  <td>Euro 2012</td>
                  <td>3 <small>(2/1)</small></td>
                  <td>0 <small>(0/0)</small></td>
                  <td>5 <small>(3/2)</small></td>
                  <td>Semis (pens)</td>
                  <td>2 MOTM</td>
                </tr>
                <tr>
                  <td>World Cup 2014</td>
                  <td>1 <small>(1/0)</small></td>
                  <td>1 <small>(1/0)</small></td>
                  <td>3 <small>(3/0)</small></td>
                  <td>Group Stage</td>
                  <td>1 MOTM</td>
                </tr>
                <tr>
                  <td>Euro 2016</td>
                  <td>3 <small>(2/1)</small></td>
                  <td>2 <small>(1/1)</small></td>
                  <td>7 <small>(3/4)</small></td>
                  <td>Winners (aet)</td>
                  <td>2 MOTM, Silver Boot</td>
                </tr>
                <tr>
                  <td>World Cup 2018</td>
                  <td>4 <small>(4/0)</small></td>
                  <td>0 <small>(0/0)</small></td>
                  <td>4 <small>(3/1)</small></td>
                  <td>Last 16</td>
                  <td>2 MOTM</td>
                </tr>
                <tr>
                  <td>Euro 2021</td>
                  <td>5 <small>(5/0)</small></td>
                  <td>1 <small>(1/0)</small></td>
                  <td>4 <small>(3/1)</small></td>
                  <td>Last 16</td>
                  <td>1 MOTM, Golden Boot</td>
                </tr>
                <tr>
                  <td>World Cup 2022</td>
                  <td>1 <small>(1/0)</small></td>
                  <td>0 <small>(0/0)</small></td>
                  <td>5 <small>(3/2)</small></td>
                  <td>Quarters</td>
                  <td>1 MOTM</td>
                </tr>
            <tr>
                  <td>Euro 2024</td>
                  <td>0 <small>(0/0)</small></td>
                  <td>0 <small>(0/0)</small></td>
                  <td>5 <small>(3/2)</small></td>
                  <td>Quarters</td>
                  <td></td>
                </tr>
                <tr>
                  <td className="text-highlight font-bold">TOTALS</td>
                  <td className="text-highlight font-bold">22 <small>(19/3)</small></td>
                  <td className="text-highlight font-bold">7 <small>(6/2)</small></td>
                  <td className="text-highlight font-bold">52 <small>(31/21)</small></td>
                  <td className="text-highlight font-bold"></td>
                  <td className="text-highlight font-bold">13 MOTM, <br /> 1 Golden Boot,  <br /> 1 Silver Boot</td>
                </tr>
              </tbody>
            </table>
          </div>

          <p>Having played in six European Championships and reaching at least the semi-finals in 3 of them, Ronaldo has played the most games of all time in the tournament, with <strong className="text-ronaldo">{rEurosApps} appearances</strong>. He's also the only player to score at <strong className="text-ronaldo">five different Euros</strong>, and is the all time leading scorer in the competition as well with <strong className="text-ronaldo">{rEurosGoals} goals</strong>.</p>

          <p>In addition to the finals tournament, Ronaldo also leads the way in <strong className="text-highlight">qualifying</strong> too, having scored a record <strong className="text-ronaldo">{rEurosQGoals} goals in European Championship qualifiers</strong>, as well as a record <strong className="text-ronaldo">{rWCQGoals} goals in European World Cup qualifying</strong> matches.</p>

          <p>Cristiano Ronaldo has also now scored the most goals in the history of the international football, with a staggering <strong className="text-ronaldo">{rIntGoals} goals</strong>!</p>

          <h2>Messi & Ronaldo International Tournament Awards and Achievements</h2>

          <div className="w-full overflow-x-scroll scrolling-touch text-sm mb-8">
            <table className="w-full">
              <thead>
                <tr>
                  <th>Lionel Messi</th>
                  <th>Cristiano Ronaldo</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ul>
                      <li>2024 Copa America Winner</li>
                      <li>2022 World Cup Winner</li>
                      <li>2022 Finalissima Winner</li>
                      <li>2021 Copa America Winner</li>
                      <li>2008 Olympics Gold Medal</li>
                      <li>2005 FIFA U20 World Cup Winner</li>
                      <li></li>
                      <li>2016 Copa America Runner-up</li>
                      <li>2015 Copa America Runner-up</li>
                      <li>2014 FIFA World Cup Runner-up</li>
                      <li>2007 Copa America Runner-up</li>
                      <li></li>
                      <li>2022 World Cup Golden Ball</li>
                      <li>2022 Finalissima Best Player</li>
                      <li>2021 Copa America Golden Ball</li>
                      <li>2015 Copa America Golden Ball</li>
                      <li>2014 World Cup Golden Ball</li>
                      <li>2007 Copa America Best Young Player</li>
                      <li>2005 U20 World Cup Golden Ball</li>
                      <li></li>
                      <li>2022 World Cup Silver Boot</li>
                      <li>2021 Copa America Golden Boot</li>
                      <li>2016 Copa America 2nd Top Scorer</li>
                      <li>2005 U20 World Cup Golden Boot</li>
                      <li></li>
                      <li>2022 World Cup Top Assister (5 players on 3)</li>
                      <li>2021 Copa America Top Assister</li>
                      <li>2018 World Cup Top Assister <br />(16 players on 2)</li>
                      <li>2016 Copa America Top Assister</li>
                      <li>2015 Copa America Top Assister</li>
                      <li>2011 Copa America Top Assister</li>
                    </ul>
                  </td>
                  <td className="align-top">
                    <ul>
                      <li>2016 Euros Winner</li>
                      <li>2019 Nations League Winner</li>
                      <li></li>
                      <li>2004 Euros Runner-up</li>
                      <li></li>
                      <li>2021 Euros Golden Boot</li>
                      <li>2019 Nations League Finals Golden Boot</li>
                      <li>2016 Euros Silver Boot</li>
                      <li>Euro 2012 joint top scorer <br />(6 players on 3 - Torres Golden Boot)</li>
                      <li></li>
                      <li>2019 Nations League Finals Goal of the Tournament</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p>Check out more of Messi and Ronaldo's international records over on the <Link to={`/records/`} className="text-highlight underline">Records</Link> page.</p>

          <h3>Competitive vs Friendly International Goals</h3>

          <p>Ronaldo has scored a much greater proportion of his international goals in competitive games than Messi has, scoring <strong className="text-ronaldo">{rCIntGoals} out of his total {rIntGoals} goals in competitive internationals ({Number((rCIntGoals/rIntGoals)*100).toFixed(2)}%)</strong>. Messi has scored <strong className="text-messi">{mCIntGoals} goals out of {mIntGoals} in competitive internationals ({Number((mCIntGoals/mIntGoals)*100).toFixed(2)}%)</strong>.</p>

          <h4>Messi International Goals &amp; Assists</h4>
          <ul className="list-inside list-disc">
            <li><strong>Competitive games:</strong> <strong className="text-messi">{mCIntGoals} goals, {mCIntAssists} assists, {mCIntApps} caps </strong></li>
            <li><strong>Friendly games:</strong> <strong className="text-messi">{mIntGoals-mCIntGoals} goals, {mIntAssists-mCIntAssists} assists, {mIntApps-mCIntApps} caps </strong></li>
          </ul>

          <h4>Ronaldo International Goals &amp; Assists</h4>
          <ul className="list-inside list-disc mb-8">
            <li><strong>Competitive games:</strong> <strong className="text-ronaldo">{rCIntGoals} goals, {rCIntAssists} assists, {rCIntApps} caps </strong></li>
            <li><strong>Friendly games:</strong> <strong className="text-ronaldo">{rIntGoals-rCIntGoals} goals, {rIntAssists-rCIntAssists} assists, {rIntApps-rCIntApps} caps </strong></li>
          </ul>

          <p>Cristiano Ronaldo clearly has the superior goalscoring stats in competitive internationals, but we must consider the level of opposition.</p>

          <h3>Level of Competitive Opposition</h3>

          <p>One reason for the contrast in friendly/competitive goals between the two is the type of opponents they typically face. In Europe, the qualification stage for the World Cup and European Championship is split into several groups, with each group featuring a couple of extremely lowly ranked teams, such as San Marino, Andorra, Liechtenstein, Luxembourg, etc.</p>
          
          <p>In South America on the other hand, they don't have a qualification stage for the Copa America, and World Cup qualification features a single group in which you must play all of the continent's heavyweights. There are also no real easy contests in this group (in comparison to some of the European micro-nations), with even the lowest ranked side Bolivia being an infamously tough team to play away due to the unusually high altitude level.</p>

          <p>As a result of these contrasting situations, Ronaldo has played many more competitive fixtures against lower ranked sides than Messi has. Cristiano has scored 28 of his competitve goals against sides ranked 100 or lower, whereas Messi has 0 goals against 100+ ranked sides having played just 2 competitive games against this level of opponent.</p>

          <p>This accounts for the huge disparity in competitive goals between the two to some degree, but even if Messi did get the opportunity to play minnows in competitive games with the same regularity as European teams do, the numbers suggest Ronaldo would still have a fairly comfortable lead in terms of competitive goals.</p>

          <p>Follow us on Twitter at <a className="text-highlight underline" target="_blank" rel="noreferrer" href="https://twitter.com/mvsrapp">@mvsrapp</a> for more Messi vs Ronaldo stats.</p>
        </CopyBlock>

        <LastUpdated type="International Stats" />
        
      </Layout>
    )
  }
}
  

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/int/"}}) {
      edges {
        node {
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          fkc
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/int/"}}) {
      edges {
        node {
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          fkc
        }
      }
    }
  }
`

export default IntAllTimePage
